import "./App.css";
import React, { useState, useEffect } from 'react';
// import logo from "./postLogo.png";
import axios from "axios";

function filterString(str, wordsToFilter) {
  // Check if the string includes any of the words to filter
  const includesWordToFilter = wordsToFilter.some(word => str.indexOf(word) !== -1);
  
  // Return the original string if it doesn't include any of the words to filter
  if (!includesWordToFilter) {
    return str;
  } else {
    return '(Contains Illegal Word!)';
  }
}

function App() {

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 5000); // fetch data every 5 seconds
    return () => clearInterval(interval);
  }, []);

  const fetchData = () => {
    axios.get('https://just-kidding.vercel.app/get-jokes')
      .then(response => {
        const temp = response.data
        setData([...temp].reverse());
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <div>
      {/* heading */}
      <div className="flex w-full justify-center items-center gap-4 py-6 bg-slate-100">
        {/* <img src={logo} alt="postman logo" className="w-16" /> */}
        <h1 className="font-bold text-4xl text-[#EF5B25]">API Workshop</h1>
      </div>

      {/* jokes */}
      <div className=" mx-20 max-[640px]:mx-0">
        <h2 className="mt-8 text-2xl max-[640px]:text-xl text-slate-700 font-bold text-center">
          EndPoint:
          <span className="text-[#EF5B25] hover:text-[#a95334] cursor-pointer font-normal">
          https://just-kidding.vercel.app/post-joke
          </span>
        </h2>

        <div className="transition-all duration-1000 ease-in-out bg-gradient-to-br from-slate-200 to-slate-500 w-full min-h-[70%] mt-6 p-6 rounded-xl flex flex-wrap justify-center gap-4">
          {data.map((joke) => (
            <>
              <div className="w-80 h-58 bg-white rounded-lg drop-shadow-lg transition transform duration-1000 ease-in-out">
                <h2 className="text-[#EF5B25] text-xl font-semibold px-4 pt-3">
                  {joke.author}
                </h2>
                <h3 className="text-slate-500 text-sm ml-4 mb-2">{joke.twitter}</h3>
              
                <div className="w-full h-[2px] bg-[#EF5B25] "></div>
                <div className="p-2 px-4 h-40 overflow-scroll hide-scrollbar">
                  <h2 className="text-lg">{joke.joke? filterString(joke.joke, ["Krishnendu"]): "Empty Parameter Sent Try Again!"}</h2>
                  {console.log(joke.joke)}
                </div>
                <div className="flex flex-row justify-between items-stretch mx-4">
                  <h2 className="text-[#EF5B25] text-sm my-3 text-center">
                    {joke.time}
                  </h2>
                  <h2 className="text-[#EF5B25] text-sm my-3 text-center">
                    {joke.date}
                  </h2>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;
